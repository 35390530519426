function enableRipple() {

    $.ripple('.btn-desktop', {
        on: 'mouseenter',
        multi: true,
        duration: 1.3
    });
    $.ripple('.swal2-styled-desktop', {
        on: 'mouseenter',
        multi: true,
        duration: 1.3
    });
}

$(function() {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="tooltip"]').on('click', function () {
            $(this).tooltip('hide');
                });
    //Ripple button animation
    $(document).on('click', '[href="#"]', function(e) { e.preventDefault(); });
    enableRipple();

    if ($(window).width() > 991) {
        $('.btn').addClass('btn-desktop');
        $('.swal2-styled').addClass('swal2-styled-desktop');
    }
    else {
        $('.btn').removeClass('btn-desktop');
        $('.swal2-styled').removeClass('swal2-styled-desktop');
        $('[data-toggle="tooltip"]').tooltip('disable');
    }

    $(window).resize(function(){  /*Bind an event handler to the "resize"*/
        if ($(window).width() > 991) {
            $('.btn').addClass('btn-desktop');
            $('.swal2-styled').addClass('swal2-styled-desktop');
            $('[data-toggle="tooltip"]').tooltip('enable');
        }
        else {
            $('.btn').removeClass('btn-desktop');
            $('.swal2-styled').removeClass('swal2-styled-desktop');
            $('[data-toggle="tooltip"]').tooltip('disable');
        }
    })
});

//Hamburger JS
var forEach=function(t,o,r){if("[object Object]"===Object.prototype.toString.call(t))for(var c in t)Object.prototype.hasOwnProperty.call(t,c)&&o.call(r,t[c],c,t);else for(var e=0,l=t.length;l>e;e++)o.call(r,t[e],e,t)};

var hamburgers = document.querySelectorAll(".hamburger");
if (hamburgers.length > 0) {
    forEach(hamburgers, function(hamburger) {
        hamburger.addEventListener("click", function() {
            this.classList.toggle("is-active");
        }, false);
    });
}
/*
$('.card-deck').slick({
    // infinite: false,
    // slidesToShow: 2,
    // slidesToScroll: 2,
    // variableWidth: false,
    // dots: false,
    responsive: [
        {
            breakpoint: 9999,
            settings: "unslick"
        },
        {
            breakpoint: 576,
            settings: {
                infinite: false,
                centerMode: false,
                arrows: false,
                dots: true,
                variableWidth: false,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
    ]
});*/
