/*
$('.publisher-content').slick({
    responsive: [
        {
            breakpoint: 99999,
            settings: "unslick"
        },
        {
            breakpoint: 575,
            settings: {
                infinite: false,
                arrows: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
});

$('.forbidden').on('click',function(e){
    e.preventDefault();
})*/
