$(function() {
    // Date Filter JS
    $('.pdate-btn').click(function() {
        $(this).addClass('hideMe');
        $('.pdate-filter').addClass('showMe');
        //Init Date Picker
        $datePicker = $('.date-picker');
        $datePicker.datepicker( {
            changeMonth: true,
            changeYear: true,
            showButtonPanel: false,
            dateFormat: 'mm-yy',
            onClose: function(dateText, inst) {
                $(this).datepicker('setDate', new Date(inst.selectedYear, inst.selectedMonth, 1));
            },
            onChangeMonthYear: function(year, month, inst) {
                $(this).val(('0' + month).slice(-2) + '-' + year);
            }
        });
    });
    $('.pdate-filter').on('keyup',function(evt) {
        if (evt.keyCode == 27) {
            if ($('.pdate-filter').hasClass('showMe')) {
                $('.pdate-filter').removeClass('showMe');
                $('.pdate-btn').removeClass('hideMe');
            }
        }
    });
    // Type Filter JS
    $('.ptype-btn').click(function() {
        $(this).addClass('hideMe');
        $('.ptype-filter').addClass('showMe');
    });
    $('.ptype-filter').on('keyup',function(evt) {
        if (evt.keyCode == 27) {
            if ($('.ptype-filter').hasClass('showMe')) {
                $('.ptype-filter').removeClass('showMe');
                $('.ptype-btn').removeClass('hideMe');
            }
        }
    });
    // Campaign Filter JS
    $('.main-adv-btn').click(function() {
        $(this).addClass('hideMe');
        $('.main-adv-filter').addClass('showMe');
    });
    $('.main-adv-filter').on('keyup',function(evt) {
        if (evt.keyCode == 27) {
            if ($('.main-adv-filter').hasClass('showMe')) {
                $('.main-adv-filter').removeClass('showMe');
                $('.main-adv-btn').removeClass('hideMe');
            }
        }
    });
});