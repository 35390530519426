$(function() {
    // Date Filter JS
    $('.date-btn').click(function() {
        $(this).addClass('hideMe');
        $('.date-filter').addClass('showMe');
        //Init Date Picker
        $datePicker = $('.date-picker');
        $datePicker.datepicker( {
            changeMonth: true,
            changeYear: true,
            showButtonPanel: false,
            dateFormat: 'mm-yy',
            onClose: function(dateText, inst) {
                $(this).datepicker('setDate', new Date(inst.selectedYear, inst.selectedMonth, 1));
            },
            onChangeMonthYear: function(year, month, inst) {
                $(this).val(('0' + month).slice(-2) + '-' + year);
            }
        });
    });
    $('.date-filter').on('keyup',function(evt) {
        if (evt.keyCode == 27) {
            if ($('.date-filter').hasClass('showMe')) {
                $('.date-filter').removeClass('showMe');
                $('.date-btn').removeClass('hideMe');
            }
        }
    });
    // Type Filter JS
    $('.type-btn').click(function() {
        $(this).addClass('hideMe');
        $('.type-filter').addClass('showMe');
    });
    $('.type-filter').on('keyup',function(evt) {
        if (evt.keyCode == 27) {
            if ($('.type-filter').hasClass('showMe')) {
                $('.type-filter').removeClass('showMe');
                $('.type-btn').removeClass('hideMe');
            }
        }
    });
    // Campaign Filter JS
    $('.campaign-btn').click(function() {
        $(this).addClass('hideMe');
        $('.campaign-filter').addClass('showMe');
    });
    $('.campaign-filter').on('keyup',function(evt) {
        if (evt.keyCode == 27) {
            if ($('.campaign-filter').hasClass('showMe')) {
                $('.campaign-filter').removeClass('showMe');
                $('.campaign-btn').removeClass('hideMe');
            }
        }
    });
});