$('.profile-pictures').slick({
    dots: false,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1,
                dots: false
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToScroll: 1,
                slidesToShow: 1,
                dots: false,
                arrows: true
            }
        }
    ]
});